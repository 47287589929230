import actions from './actions';

const initialState = {
  items: [],
  current: 0,
  isLoading: false,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_INFO_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case actions.FETCH_INFO_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        items: data?.items_all ?? [],
        current: data?.current_all ?? 0,
        isLoading: false,
      };
    }
    case actions.FETCH_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
