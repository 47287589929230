const initialState = {
  items: [],
  isLoading: false,
  total: 0,
  offset: 0,
  error: null,
};

const helpersReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default helpersReducer;
