import generateRequestActions from 'redux/factories/generateRequestActions';
import { ActionType } from './types';

const actions: ActionType = {
  ...generateRequestActions(['fetch'], 'analysis', 'runs'),
  ...generateRequestActions(['upload'], 'analysis', 'run_results'),
  ...generateRequestActions(['fetch'], 'analysis', 'run'),
  ...generateRequestActions(['update'], 'analysis', 'sample'),
  ...generateRequestActions(['update'], 'analysis', 'run'),
  ...generateRequestActions(['fetch'], 'analysis', 'wellplate'),
  ...generateRequestActions(['fetch'], 'analysis', 'run_updates_by_sse'),
  ...generateRequestActions(['delete'], 'analysis', 'run'),
  ...generateRequestActions(['update'], 'analysis', 'samples_in_bulk'),
  ...generateRequestActions(['lock'], 'analysis', 'run'),
  ...generateRequestActions(['upload'], 'analysis', 'run_plot'),
};

export default actions;
