import { notification } from 'antd';
import React from 'react';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchResultPlot } from 'services/resultPlots';
import actions from './actions';

export function* callFetchResultPlots({ payload }) {
  try {
    const { data } = yield call(fetchResultPlot, payload);

    yield put({
      type: actions.FETCH_PLOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_PLOTS_FAILURE });
    notification.error({ message: error.message, duration: null });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_PLOTS_REQUEST, callFetchResultPlots)]);
}
