/* eslint-disable import/prefer-default-export */
import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

export const loadTimeline = async (id: string) => {
  try {
    const timeline = await axiosClient.get(`/runs/${id}/timeline/`);
    return timeline;
  } catch (error) {
    throw new Error(handleError(error));
  }
};
