import { notification } from 'antd';
import { END, eventChannel } from 'redux-saga';
import { all, call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { fetchRuns, publishRun } from 'services/runs';
import cookieStorage from 'utils/cookie';
import sseClient from 'utils/sseClient';
import actions from './actions';

export function* callLoadRuns({ payload }) {
  try {
    const response = yield call(fetchRuns, payload);

    yield put({
      type: actions.FETCH_RUNS_SUCCESS,
      payload: {
        data: response.data,
        firstPage: !response.data.previous,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RUNS_FAILURE });
    notification.error({
      message: error.message,
    });
  }
}

export function* callPublishRun({ payload }) {
  try {
    const response = yield call(publishRun, payload);

    yield put({
      type: actions.PUBLISH_RUN_SUCCESS,
      payload: {
        data: response.data,
      },
    });
    notification.success({
      message: 'Run updated',
    });
  } catch (error) {
    yield put({
      type: actions.PUBLISH_RUN_FAILURE,
      payload: {
        runId: payload.runId,
      },
    });
    notification.error({
      message: error.message,
    });
  }
}

async function createChannel(eventSource) {
  return eventChannel((emitter) => {
    eventSource.onopen = (e) => {
      emitter(e);
    };

    eventSource.onerror = (err) => {
      console.error(err);
    };

    eventSource.addEventListener('message', (e) => {
      return emitter({ action: 'message', data: e.data });
    });
    eventSource.addEventListener('disconnect', (e) => {
      // this causes the channel to close
      emitter(END);
    });

    return () => {};

    // return () => {
    //   console.info('closing connection...');
    //   eventSource.close();
    //   emitter(END);
    // };
  });
}

export function* runWatcher() {
  if (process.env.REACT_APP_SSE_RUN !== 'on') {
    return;
  }

  const cookie = cookieStorage();
  const isUserAuthorized = cookie.getItem('accessToken');

  if (isUserAuthorized) {
    const eventSource = sseClient(
      `${process.env.REACT_APP_SSE_URL_C}/poolbatches/updates/`,
    );

    const channel = yield call(createChannel, eventSource);

    try {
      while (true) {
        const { action, data } = yield take(channel);

        switch (action) {
          case 'message': {
            const parsedData = JSON.parse(data);

            yield put({
              type: actions.FETCH_RUN_BY_SSE,
              payload: {
                data: parsedData?.instance || {},
              },
            });

            break;
          }
          // default:
          //   return;
        }
      }
    } catch (error) {
      throw error;
    }
  }
  return;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_RUNS_REQUEST, callLoadRuns),
    takeEvery(actions.PUBLISH_RUN_REQUEST, callPublishRun),
    spawn(runWatcher),
  ]);
}
