import intakeReceiptLogActions from 'redux/intakeReceiptLog/actions';
import poolActions from 'redux/pools/actions';
import racksActions from 'redux/racks/actions';
import sessionActions from 'redux/scanSessions/actions';

const actions = {
  SHOW_MODAL: 'modal/SHOW_MODAL',
  HIDE_MODAL: 'modal/HIDE_MODAL',
  SET_ERROR: 'modal/SET_ERROR',
  ...intakeReceiptLogActions,
  ...sessionActions,
  ...racksActions,
  ...poolActions,
};

export default actions;
