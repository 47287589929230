import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

type ResultPlotPayloadType = {
  id: string;
};

export const fetchResultPlot = async (payload: ResultPlotPayloadType) => {
  const { id } = payload;

  try {
    const resultPlot = await axiosClient.get(`/runs/results/${id}/read-plot`);
    return resultPlot;
  } catch (error) {
    throw new Error(handleError(error));
  }
};
