// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import { constants } from 'utils/constants';
import styles from './style.module.scss';

const SubBar = ({ location }) => {
  const pathArray = location.pathname.slice(1).split('/');
  const company = useSelector((state) => state.companies.singleCompany);

  const getBreadcrumbs = () => {
    if (
      pathArray[0] === 'users' ||
      pathArray[0] === 'session' ||
      pathArray[0] === 'pool-scans' ||
      pathArray[0] === 'rack-scans' ||
      pathArray[0] === 'reflex-list'
    ) {
      return (
        <li className={styles.breadcrumb}>
          <Link to={`/${pathArray[0]}`} className={styles.breadcrumbLink}>
            {pathArray[0].replace('-', ' ')}
          </Link>
        </li>
      );
    }
    if (pathArray[0] === 'analysis-runs') {
      return (
        <li className={styles.breadcrumb}>
          <Link
            // eslint-disable-next-line max-len
            to={`/${pathArray[0]}?from=${constants.analysisRuns.initialDates.from}&to=${constants.analysisRuns.initialDates.to}`}
            className={styles.breadcrumbLink}
          >
            {pathArray[0].replace('-', ' ')}
          </Link>
        </li>
      );
    }
    if (pathArray[0] === 'runs') {
      return (
        <li className={styles.breadcrumb}>
          <Link
            // eslint-disable-next-line max-len
            to={`/${pathArray[0]}?from=${constants.defaultRunsRange.from}&to=${constants.defaultRunsRange.to}`}
            className={styles.breadcrumbLink}
          >
            {pathArray[0].replace('-', ' ')}
          </Link>
        </li>
      );
    }
    return (
      <li className={styles.breadcrumb}>
        {pathArray.map((path, index) => {
          if (index + 1 === pathArray.length && pathArray.length > 1) {
            return (
              <span key={path} className={styles.breadcrumb__last}>
                <Switch>
                  <Route exact path="/companies/:id">
                    {company.name}
                  </Route>
                  <Route path="*">{path}</Route>
                </Switch>
              </span>
            );
          }
          return (
            <Fragment key={path}>
              <Link to={`/${path}`} className={styles.breadcrumbLink}>
                {path.replaceAll('-', ' ')}
              </Link>
              {index + 1 !== pathArray.length && pathArray.length > 1 && (
                <span className={styles.breadcrumbLink__dash}>-</span>
              )}
            </Fragment>
          );
        })}
      </li>
    );
  };

  return (
    <div className={styles.subbar}>
      <ul className={`${styles.breadcrumbs} mr-4`}>{getBreadcrumbs()}</ul>
    </div>
  );
};

SubBar.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default SubBar;
