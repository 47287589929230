import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';
import React from 'react';
import styles from './style.module.scss';

const BackTopComponent = () => {
  return (
    <BackTop>
      <div className={styles.backTop}>
        <VerticalAlignTopOutlined />
      </div>
    </BackTop>
  );
};

export default BackTopComponent;
