import actions from './actions';

const initialState = {
  items: [],
  isLoading: false,
};

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_TIMELINE_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case actions.LOAD_TIMELINE_SUCCESS: {
      const { data } = action.payload;

      return {
        items: data.results,
        isLoading: false,
      };
    }
    case actions.LOAD_TIMELINE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default timelineReducer;
