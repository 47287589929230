const config = {
  mirimus: {
    name: 'Mirimus',
    id: 'mirimus',
    contacts: {
      email: 'testingsupport@mirimus.com',
    },
    timezone: 'America/New_York',
    naming: {
      rack: 'PoolRack',
    },
  },
  aclv: {
    name: 'Accelevir',
    id: 'aclv',
    contacts: {
      email: 'mhays@accelevir.com',
    },
    timezone: 'America/New_York',
    naming: {
      rack: 'PoolRack',
    },
  },
  wynn: {
    name: 'Mirimus',
    id: 'wynn',
    contacts: {
      email: 'testingsupport@mirimus.com',
    },
    timezone: 'America/Los_Angeles',
    naming: {
      rack: 'KingFisher Rack',
    },
  },
};

export default config;
