import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchResultList,
  fetchRunMethods,
  fetchTestParams,
} from 'services/classificators';
import actions from './actions';

export function* callFetchTestParams() {
  try {
    const { data } = yield call(fetchTestParams);

    yield put({
      type: actions.FETCH_TEST_PARAMS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TEST_PARAMS_FAILURE });

    notification.error({
      message: 'Failed to get testing parameters',
      description: error.message,
      duration: null,
    });
  }
}

export function* callFetchRunMethods({ payload }) {
  try {
    const { data } = yield call(fetchRunMethods);

    yield put({
      type: actions.FETCH_RUN_METHODS_SUCCESS,
      payload: {
        data,
        ...payload,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RUN_METHODS_FAILURE });

    notification.error({
      message: 'Failed to get run methods',
      description: error.message,
      duration: null,
    });
  }
}

export function* callFetchResultList({ payload }) {
  try {
    const response = yield call(fetchResultList, payload);

    yield put({
      type: actions.FETCH_RESULT_LIST_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RESULT_LIST_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TEST_PARAMS_REQUEST, callFetchTestParams),
    takeEvery(actions.FETCH_RUN_METHODS_REQUEST, callFetchRunMethods),
    takeEvery(actions.FETCH_RESULT_LIST_REQUEST, callFetchResultList),
  ]);
}
