import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

type QueryParamsType = {
  limit: number;
  offset: number;
  search: string;
};

export const fetchRuns = async (query: QueryParamsType) => {
  try {
    const runs = await axiosClient.get('/runs/', {
      params: {
        ...query,
      },
    });
    return runs;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

type OptionsType = {
  action: string;
  filename: string;
  data: {};
  file: string | Blob;
  headers: {};
  withCredentials: boolean;
  method: string;
  onSuccess: (value: any) => void;
  onError: (value: any) => void;
};

type FileType = {
  id: string;
  options: OptionsType;
};

type PlotType = {
  run_id: string;
  plot_type: string;
  plot_file: string | Blob;
};

export const uploadRunResults = async (payload: FileType) => {
  const { file, onSuccess, onError } = payload?.options;

  try {
    const formData = new FormData();
    formData.append('result_file', file);

    const uploadedRun = await axiosClient.post(
      `/runs/${payload.id}/import/`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    onSuccess(uploadedRun);
    return uploadedRun;
  } catch (error) {
    onError(error);
    throw new Error(handleError(error));
  }
};

export const uploadRunPlot = async (payload: PlotType) => {
  const { plot_type, plot_file } = payload;
  try {
    const formData = new FormData();
    formData.append('plot_type', plot_type);
    formData.append('plot_file', plot_file);

    const uploadedPlot = await axiosClient.post(
      `/runs/${payload.run_id}/import-plot/`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    return uploadedPlot;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const fetchRun = async (id: string) => {
  try {
    const run = await axiosClient.get(`/runs/results/${id}/entries`);
    return run;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const fetchWellplate = async (id: string) => {
  try {
    return await axiosClient.get(`/runs/${id}/tubes/`);
  } catch (error) {
    throw new Error(handleError(error));
  }
};

type ValuesType = {
  analysis_result: string;
  rerun_action: string;
  auto_publish: boolean;
};

type SampleType = {
  id: string;
  values: ValuesType;
};

export const updateSample = async ({ id, values }: SampleType) => {
  try {
    const sample = await axiosClient.patch(`/runs/results/sample/${id}/`, {
      ...values,
    });
    return sample;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

type RunType = {
  id: string;
  field: string;
  value: string;
};

export const updateRun = async ({ id, field, value }: RunType) => {
  try {
    const run = await axiosClient.patch(`/runs/${id}/status/`, {
      [field]: value,
    });
    return run;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const removeRun = async ({ id }: { id: string }) => {
  try {
    return await axiosClient.delete(`/runs/${id}/delete/`);
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const updateSamplesInBulk = async (payload: SampleType[]) => {
  try {
    const samples = await axiosClient.patch(
      '/runs/results/sample/update/',
      payload,
    );
    return samples;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const lockRun = async ({ id }: { id: string }) => {
  try {
    return await axiosClient.put(`/runs/${id}/lock/`, { is_locked: true });
  } catch (error) {
    throw new Error(handleError(error));
  }
};
