import cookieStorage from 'utils/cookie';
import { CookieStorage } from 'cookie-storage';

const cookie: CookieStorage | null = cookieStorage();

export const RemoveAuth = () => {
  cookie?.removeItem('accessToken');
  cookie?.removeItem('userID');
  return (window.location.href = '/system/login');
};
