const actions = {
  GET_COMPANY_REQUEST: 'companies/GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'companies/GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'companies/GET_COMPANY_FAILURE',

  FETCH_COMPANIES_REQUEST: 'companies/FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'companies/FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'companies/FETCH_COMPANIES_FAILURE',
};

export default actions;
