import axiosClient from 'utils/axiosClient';
import nameWithExtension from 'utils/nameWithExtension';
import saveBlobAs from 'utils/saveAsBlob';

export const fetchSampleCount = async (query) => {
  try {
    const sampleCount = await axiosClient.get('/pools/counts/', {
      params: { ...query },
    });

    return sampleCount;
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadSampleCount = async (query) => {
  try {
    const response = await axiosClient.get('/pools/counts/export/', {
      params: { ...query },
      responseType: 'blob',
    });

    const requestContentType = response.headers['content-type'];
    const parsedName = response.headers['content-disposition']
      ?.split(';')[1]
      ?.split('="')[1]
      ?.split('.')[0];
    const blobData = new Blob([response.data], {
      type: requestContentType,
    });

    saveBlobAs(
      blobData,
      nameWithExtension(parsedName || `Unknown_download`, requestContentType),
    );

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
