import findKey from 'lodash.findkey';
import mapValues from 'lodash.mapvalues';
import reduce from 'lodash.reduce';
import sortBy from 'lodash.sortby';
import { RunType } from 'models/run.model';
import { constants } from 'utils/constants';

export const isControlSample = (
  sample: string = '',
  controlSamples: string[] = [],
): boolean => {
  return controlSamples.some((e) => sample.includes(e));
};

type MethodType = string[];

type TestTypeType = {
  text: string;
  label: string;
  value: string;
};

type TestTypeMethodsType = {
  [key: string]: string[];
};

type TargetKeyLabelsType = {
  [key: string]: string;
};

type TargetsByTestTypeType = {
  [key: string]: string;
};

type TargetsType = {
  [key: string]: TargetsByTestTypeType;
};

type ControlResultType = {
  key: string;
  value: string;
};

type RunTypeType = {
  text: string;
  value: string;
};

type RawDataType = {
  methods?: MethodType[];
  test_types?: TestTypeType[];
  test_type__methods?: TestTypeMethodsType;
  target_key_labels?: TargetKeyLabelsType;
  targets?: TargetsType;
  control_results?: ControlResultType[];
  run_types?: RunTypeType[];
};

export const formatRunMethods = (rawData: RawDataType = {}) => {
  const runMethods = rawData.methods?.reduce?.((acc, method) => {
    const value = method[0];
    const label = method[1];
    const targets = reduce(
      // @ts-ignore
      rawData.targets?.[value],
      (acc, value, key) => {
        const targetLabel = rawData.target_key_labels?.[key] ?? key;
        return {
          ...acc,
          [targetLabel]: value,
        };
      },
      {},
    );

    const testType = findKey(rawData.test_type__methods, (methods: string[]) =>
      // @ts-ignore
      methods.includes(value),
    );

    return {
      ...acc,
      // @ts-ignore
      [value]: { label, value, targets, testType },
    };
  }, {});

  return runMethods;
};

export const formatTestTypesMethods = (rawData: RawDataType = {}) => {
  const methodsValueLabel = reduce(
    rawData.methods,
    (acc, method) => {
      return {
        ...acc,
        // @ts-ignore
        [method[0]]: method[1],
      };
    },
    {},
  );

  const testTypesMethods = mapValues(
    rawData.test_type__methods,
    (methods: string[]) => {
      const formattedMethods = methods.map((value) => {
        return {
          value,
          // @ts-ignore
          label: methodsValueLabel[value],
        };
      });

      return sortBy(formattedMethods, 'label');
    },
  );

  return testTypesMethods;
};

export const formatTestTypes = (rawData: RawDataType = {}) => {
  const testTypes = reduce(
    rawData.test_types,
    (acc, testType) => {
      return {
        ...acc,
        [testType.value]: testType.label,
      };
    },
    {},
  );

  return testTypes;
};

export const getDisabled = (run: RunType, action: string) => {
  switch (action) {
    case 'delete-run':
      return {
        disabled:
          run.status === constants.runStatuses.finalizing ||
          run.status === constants.runStatuses.published ||
          run.is_locked,
      };

    case 'download-template':
      return {
        disabled: run.status === constants.runStatuses.pendingTemplate,
      };

    case 'lock-run':
      return {
        disabled:
          run.status === constants.runStatuses.pendingTemplate ||
          run.status === constants.runStatuses.pendingResults ||
          run.status === constants.runStatuses.finalizing ||
          run.status === constants.runStatuses.published ||
          run.is_locked,
      };

    case 'upload-results':
    case 'upload-plot':
      return {
        disabled:
          run.status === constants.runStatuses.pendingTemplate ||
          run.status === constants.runStatuses.pendingResults ||
          run.status === constants.runStatuses.finalizing ||
          run.status === constants.runStatuses.published ||
          run.is_locked,
      };

    default:
      return {
        disabled: false,
      };
  }
};

export const formatRunTypes = (rawData: RawDataType = {}) => {
  const runTypes = reduce(
    rawData.run_types,
    (acc, runType) => {
      return {
        ...acc,
        [runType.value]: runType.text,
      };
    },
    {},
  );

  return runTypes;
};
