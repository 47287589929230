import { Drawer } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/drawer/actions';

const DefaultDrawer = () => {
  const dispatch = useDispatch();

  const { drawerProps, isOpen } = useSelector((state) => state.drawer);

  const onClose = () => {
    dispatch({ type: actions.HIDE_DRAWER });
  };

  return (
    <Drawer open={isOpen} onClose={onClose} {...drawerProps}>
      {drawerProps.content?.()}
    </Drawer>
  );
};

export default DefaultDrawer;
