import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import analysisRuns from './analysisRuns/reducers';
import classificators from './classificators/reducers';
import companies from './companies/reducers';
import drawer from './drawer/reducers';
import helpersReducer from './helpers/reducers';
import intakeDashboard from './intakeDashboard/reducers';
import intakeReceiptLog from './intakeReceiptLog/reducers';
import sampleCount from './sampleCount/reducers';
import inventory from './inventory/reducers';
import menu from './menu/reducers';
import modal from './modal/reducers';
import notes from './notes/reducers';
import pools from './pools/reducers';
import racks from './racks/reducers';
import reflex from './reflex/reducers';
import runTemplate from './runTemplate/reducers';
import runs from './runs/reducers';
import scanSessions from './scanSessions/reducers';
import scanners from './scanners/reducers';
import search from './search/reducers';
import settings from './settings/reducers';
import * as types from './storeTypes';
import timeline from './timeline/reducers';
import user from './user/reducers';
import userSettings from './userSettings/reducers';
import resultPlots from './resultPlots/reducers';

const rootReducer = (history: any) =>
  combineReducers({
    analysisRuns,
    classificators,
    companies,
    drawer,
    helpersReducer,
    intakeDashboard,
    intakeReceiptLog,
    sampleCount,
    inventory,
    menu,
    modal,
    notes,
    pools,
    racks,
    reflex,
    router: connectRouter(history),
    runs,
    runTemplate,
    scanners,
    scanSessions,
    search,
    settings,
    timeline,
    user,
    userSettings,
    resultPlots,
  });

export default rootReducer;
export type RootState = {
  user: types.UserState;
  settings: types.SettingState;
  pools: types.PoolState;
};
