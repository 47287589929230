import { Button, notification } from 'antd';
import React from 'react';

type BtnType = {
  key: string;
};

const closeBtn = ({ key }: BtnType) => {
  return (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      GOT IT
    </Button>
  );
};

export default closeBtn;
