import { notification } from 'antd';
import { push } from 'connected-react-router';
import { END, eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects';
import modalActions from 'redux/modal/actions';
import notesActions from 'redux/notes/actions';
import {
  closeSession,
  createSession,
  fetchSessionById,
  fetchSessionId,
  fetchSessions,
  reassignIntakeItem,
  reopenSession,
  updateSession,
} from 'services/scanSessions';
import { fetchScannerById } from 'services/scanners';
import {
  deleteScan,
  deleteTube,
  fetchActiveScans,
  fetchScanById,
  movePoolscan,
  updateScan,
  updateTube,
  uploadScan,
  createEmptyScan,
  copyScan,
} from 'services/scans';
import { constants } from 'utils/constants';
import cookieStorage from 'utils/cookie';
import sseClient from 'utils/sseClient';
import { emptyPositionsArr, incorrectPositionsArr } from 'utils/tubeRules';
import actions from './actions';
import {
  getEmptyPositions,
  getIncorrectPositions,
  getSelectedCode,
} from './selectors';

const formatResponseScanTubes = (response) => {
  const colsNumber = 8;
  return response
    ?.map?.((_, idx) => {
      if (idx % colsNumber === 0) {
        const rightIdx = idx + colsNumber;
        return Object.assign(
          {},
          ...response?.slice(idx, rightIdx)?.map?.((obj) => ({
            letter: obj?.position?.[0],
            [`col${obj?.position?.substr(1)}`]: {
              ...obj,
              status: obj?.status,
            },
          })),
        );
      }
    })
    ?.filter((item) => item?.letter);
};

export function* callFetchScanSessions({ payload }) {
  try {
    const { data } = yield call(fetchSessions, payload);

    yield put({
      type: actions.FETCH_SCAN_SESSIONS_SUCCESS,
      payload: {
        data: data?.results ?? [],
        total: data.count,
        firstPage: !data.previous,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SCAN_SESSIONS_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callFetchScanSessionById({ payload }) {
  const { sessionId, redirectCallback } = payload;

  try {
    const { data } = yield call(fetchSessionById, sessionId);

    yield put({
      type: actions.FETCH_SCAN_SESSION_BY_ID_SUCCESS,
      payload: {
        data,
      },
    });

    const barcodes = data.scans.reduce((acc, scan) => {
      return [...acc, ...scan.barcodes];
    }, []);

    return yield put({
      type: notesActions.FETCH_NOTES_REQUEST,
      payload: {
        data: barcodes,
        type: 'poolscan',
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SCAN_SESSION_BY_ID_FAILURE });
    notification.error({ message: error.message, duration: 10 });

    if (redirectCallback) {
      return redirectCallback();
    }
  }
}

export function* callUpdateSession({ payload }) {
  try {
    let response = null;

    if (payload.isSaveSession) {
      response = yield call(updateSession, payload);
    } else {
      response = yield call(closeSession);
    }

    yield put({
      type: actions.UPDATE_SESSION_SUCCESS,
      payload: {
        data: response.data,
      },
    });

    if (payload.callback) {
      yield put({
        type: actions.FETCH_SESSION_ID_SUCCESS,
        payload: {
          sessionId: null,
        },
      });

      payload.callback();
    }
    yield put({
      type: modalActions.HIDE_MODAL,
    });

    if (payload.isSaveSession) {
      notification.success({
        message: 'Session updated',
      });
    } else {
      notification.success({
        message: 'Session canceled',
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_SESSION_FAILURE,
      payload: {
        error,
      },
    });

    throw new Error(error);
  }
}

export function* callFetchScanById({ payload }) {
  try {
    const response = yield call(fetchScanById, payload.scanId);

    const tubesInfo = response?.data?.scan_tubes;

    yield put({
      type: actions.FETCH_SCAN_BY_ID_SUCCESS,
      payload: {
        data: {
          ...response?.data,
          items: formatResponseScanTubes(tubesInfo),
        },
      },
    });

    if (
      payload.callback &&
      response?.data?.possibly_reversed &&
      response?.data?.status !== constants.scanStatuses.completed
    ) {
      yield call(payload.callback);
    }
  } catch (error) {
    yield put({ type: actions.FETCH_SCAN_BY_ID_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callScannerStatusById({ payload }) {
  try {
    const { data } = yield call(fetchScannerById, payload.scannerId);

    yield put({
      type: actions.CHECK_SCANNER_STATUS_BY_ID_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: actions.CHECK_SCANNER_STATUS_BY_ID_FAILURE,
    });

    notification.error({
      message: error.message,
    });
  }
}

export function* callUpdateTube({ payload }) {
  const { pooling } = constants.tubes;

  try {
    const response = yield call(updateTube, payload);

    const emptyPositions = yield select(getEmptyPositions);
    const empty_positions = yield call(
      emptyPositionsArr,
      emptyPositions,
      response.data,
    );

    const incorrectPositions = yield select(getIncorrectPositions);
    const incorrect_positions = yield call(
      incorrectPositionsArr,
      incorrectPositions,
      response.data,
    );

    // const isInvalidScan = incorrect_positions?.length > 0;

    const scanData = {
      empty_positions,
      incorrect_positions,
      last_modified_on: response.data.last_modified_on,
      last_modified_by: response.data.last_modified_by,
      ...(response?.data?.status === pooling.status
        ? { pool_id: response?.data?.tube_id }
        : {}),
      // status: isInvalidScan
      //   ? constants.scanStatuses.invalid
      //   : constants.scanStatuses.started,
    };

    yield put({
      type: actions.UPDATE_TUBE_SUCCESS,
      payload: {
        data: {
          row: {
            letter: response?.data?.position?.[0],
            [`col${response?.data?.position?.[1]}`]: {
              ...response?.data,
              status: response?.data?.status,
            },
          },
          tube: response.data,
          scanData,
        },
      },
    });

    if (!payload.isRack) {
      yield put({
        type: actions.CHANGE_SESSION_DATA,
        payload: {
          data: {
            scanId: payload.scanId,
            scanData,
          },
        },
      });
    }

    notification.success({
      message: 'Tube updated',
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_TUBE_FAILURE });

    notification.error({
      message: error.message,
      duration: 10,
    });
  }
}

export function* callInvalidateTube({ payload }) {
  try {
    const selectedCode = yield select(getSelectedCode);
    const response = yield call(updateTube, {
      ...payload,
      data: { status: selectedCode.status },
    });

    const scanData = {
      last_modified_on: response.data.last_modified_on,
      last_modified_by: response.data.last_modified_by,
    };

    yield put({
      type: actions.INVALIDATE_TUBE_SUCCESS,
      payload: {
        data: {
          row: {
            letter: response?.data?.position?.[0],
            [`col${response?.data?.position?.[1]}`]: {
              ...response?.data,
              status: response?.data?.status,
              color: response?.data?.color,
            },
          },
          tube: response.data,
          scanData,
        },
      },
    });

    yield put({
      type: actions.CHANGE_SESSION_DATA,
      payload: {
        data: {
          scanId: payload.scanId,
          scanData,
        },
      },
    });

    yield put({
      type: modalActions.HIDE_MODAL,
    });

    notification.success({
      message: 'Tube updated',
    });
  } catch (error) {
    yield put({
      type: actions.INVALIDATE_TUBE_FAILURE,
      error,
    });

    notification.error({
      message: error.message,
      duration: 10,
    });

    throw Error(error);
  }
}

export function* callDeleteTube({ payload }) {
  try {
    const response = yield call(deleteTube, payload);

    const emptyPositions = yield select(getEmptyPositions);
    const empty_positions = yield call(
      emptyPositionsArr,
      emptyPositions,
      response.data,
    );

    const incorrectPositions = yield select(getIncorrectPositions);
    const incorrect_positions = yield call(
      incorrectPositionsArr,
      incorrectPositions,
      response.data,
    );

    // const isInvalidScan = incorrect_positions?.length > 0;

    const scanData = {
      empty_positions,
      incorrect_positions,
      last_modified_on: response.data.last_modified_on,
      last_modified_by: response.data.last_modified_by,
      // status: isInvalidScan
      //   ? constants.scanStatuses.invalid
      //   : constants.scanStatuses.started,
    };

    yield put({
      type: actions.DELETE_TUBE_SUCCESS,
      payload: {
        data: {
          row: {
            letter: response?.data?.position?.[0],
            [`col${response?.data?.position?.[1]}`]: {
              ...response?.data,
              status: response?.data?.status,
            },
          },
          tube: response.data,
          scanData,
        },
      },
    });

    if (!payload.isRack) {
      yield put({
        type: actions.CHANGE_SESSION_DATA,
        payload: {
          data: {
            scanId: payload.scanId,
            scanData,
          },
        },
      });
    }

    notification.success({
      message: 'Tube deleted',
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_TUBE_FAILURE,
      payload: {
        data: error.message ?? null,
      },
    });

    notification.error({
      message: error.message ?? 'Failure!',
      duration: 10,
    });
  }
}

export function* callFetchSessionId({ payload }) {
  try {
    const response = yield call(fetchSessionId);

    yield put({
      type: actions.FETCH_SESSION_ID_SUCCESS,
      payload: {
        data: response?.data,
      },
    });

    if (payload?.redirectCallback) {
      return payload?.redirectCallback();
    }
  } catch (error) {
    notification.error({
      message: 'Something went wrong',
    });

    throw Error(error);
  }
}

export function* callCreateSession({ payload }) {
  try {
    const response = yield call(createSession, payload);

    yield put({
      type: actions.CREATE_SESSION_SUCCESS,
      payload: {
        sessionId: response?.data?.id,
      },
    });

    if (payload?.callback && response?.data?.id) {
      yield call(payload.callback, response?.data?.id);
    }
  } catch (error) {
    yield put({ type: actions.CREATE_SESSION_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callUpdateScan({ payload }) {
  try {
    const response = yield call(updateScan, payload);

    yield put({
      type: actions.UPDATE_SCAN_BY_ID_SUCCESS,
      payload: {
        data: {
          ...response.data,
          items: formatResponseScanTubes(response.data.scan_tubes),
        },
      },
    });

    yield put({
      type: actions.CHANGE_SESSION_DATA,
      payload: {
        data: {
          scanId: response.data.id,
          scanData: response.data,
        },
      },
    });

    yield put({ type: modalActions.HIDE_MODAL });
    notification.success({ message: 'Scan updated' });
  } catch (error) {
    yield put({ type: actions.UPDATE_SCAN_BY_ID_FAILURE });
    yield put({ type: modalActions.HIDE_MODAL });
    notification.error({ message: error.message ?? 'Scan not updated' });
  }
}

export function* callCancelScan({ payload }) {
  try {
    const response = yield call(updateScan, payload);

    yield put({
      type: actions.CANCEL_SCAN_BY_ID_SUCCESS,
      payload: {
        data: response.data,
      },
    });

    yield put({
      type: actions.CHANGE_SESSION_DATA,
      payload: {
        data: {
          scanId: response.data.id,
          scanData: response.data,
        },
      },
    });

    notification.success({
      message: 'Scan updated',
    });
  } catch (error) {
    yield put({
      type: actions.CANCEL_SCAN_BY_ID_FAILURE,
    });

    notification.error({
      message: error.message ?? 'Scan not updated',
    });
  }
}

export function* callVoidScan({ payload }) {
  try {
    yield call(deleteScan, payload);

    yield put({ type: actions.VOID_SCAN_BY_ID_SUCCESS });

    yield call(payload.reloadSession);

    notification.success({ message: 'Scan was voided' });
  } catch (error) {
    yield put({
      type: actions.VOID_SCAN_BY_ID_FAILURE,
      payload: {
        error,
      },
    });

    throw new Error(error);
  }
}

export function* callDeleteScan({ payload }) {
  try {
    yield call(deleteScan, payload);

    yield put({
      type: actions.DELETE_SCAN_BY_ID_SUCCESS,
      payload: { sessionId: payload.sessionId, scanId: payload.id },
    });

    if (payload.sessionId) {
      yield put({
        type: actions.FETCH_SCAN_SESSION_BY_ID_REQUEST,
        payload: { sessionId: payload.sessionId },
      });
    }

    notification.success({ message: 'Scan was deleted successfully!' });
  } catch (error) {
    yield put({
      type: actions.DELETE_SCAN_BY_ID_FAILURE,
      payload: {
        error,
      },
    });

    throw new Error(error);
  }
}

export function* callFetchActiveScans({ payload }) {
  try {
    const response = yield call(fetchActiveScans, payload);

    yield put({
      type: actions.FETCH_ACTIVE_SCANS_SUCCESS,
      payload: {
        data: response.data,
        status: response.status,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ACTIVE_SCANS_FAILURE,
    });
  }
}

export function* callUploadScan({ payload }) {
  try {
    const { data } = yield call(uploadScan, payload);

    yield put({ type: actions.UPLOAD_SCAN_SUCCESS });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: 'Scan uploaded successfully' });
  } catch (error) {
    yield put({ type: actions.UPLOAD_SCAN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callCreateEmptyScan({ payload }) {
  try {
    const response = yield call(createEmptyScan, payload);

    yield put({ type: actions.CREATE_EMPTY_SCAN_SUCCESS });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: response.data });
  } catch (error) {
    yield put({ type: actions.CREATE_EMPTY_SCAN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callCopyScan({ payload }) {
  try {
    const response = yield call(copyScan, payload);

    yield put({ type: actions.COPY_SCAN_SUCCESS });

    notification.success({ message: response.data });
  } catch (error) {
    yield put({ type: actions.COPY_SCAN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

async function createChannel(eventSource) {
  return eventChannel((emitter) => {
    eventSource.onopen = (e) => {
      console.info('connection is established');
      emitter(e);
    };

    eventSource.onerror = (err) => {
      console.error(err);
    };

    eventSource.addEventListener('message', (e) => {
      return emitter({ action: 'message', data: e.data });
    });
    eventSource.addEventListener('disconnect', (e) => {
      // this causes the channel to close
      emitter(END);
    });

    return () => {};

    // return () => {
    //   console.info('closing connection...');
    //   eventSource.close();
    //   emitter(END);
    // };
  });
}

export function* scanWatcher() {
  if (process.env.REACT_APP_SSE_SCANS !== 'on') {
    return;
  }

  const cookie = cookieStorage();
  const isUserAuthorized = cookie.getItem('accessToken');
  const userID = cookie.getItem('userID');

  if (!userID) {
    return yield put({
      type: 'user/LOGOUT',
      payload: {
        redirect: () => push('/system/login'),
      },
    });
  }
  if (isUserAuthorized) {
    const eventSource = sseClient(
      `${process.env.REACT_APP_SSE_URL_A}/new-scans/${userID}/`,
    );

    const channel = yield call(createChannel, eventSource);

    try {
      while (true) {
        const { action, data } = yield take(channel);

        switch (action) {
          case 'message': {
            yield put({
              type: actions.FETCH_SCAN_BY_SSE,
              payload: {
                data: JSON.parse(data),
              },
            });

            break;
          }
          // default:
          //   return;
        }
      }
    } catch (error) {
      throw error;
    }
  }
  return;
}

export function* callReopenSession({ payload }) {
  try {
    const { data } = yield call(reopenSession, payload);

    yield put({
      type: actions.REOPEN_SESSION_SUCCESS,
      payload: { data },
    });

    yield call(payload.callback);

    yield put({ type: modalActions.HIDE_MODAL });
  } catch (error) {
    yield put({ type: actions.REOPEN_SESSION_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callReassignIntakeItem({ payload }) {
  const { data, resetForm } = payload;

  try {
    const response = yield call(reassignIntakeItem, data);

    yield put({
      type: actions.REASSIGN_INTAKE_ITEM_SUCCESS,
      payload: response,
    });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: 'Session moved' });

    return yield call(resetForm);
  } catch (error) {
    yield put({ type: actions.REASSIGN_INTAKE_ITEM_FAILURE });
    return notification.error({ message: error.message, duration: 10 });
  }
}

export function* callMovePoolscan({ payload }) {
  const { data, resetForm } = payload;

  try {
    const response = yield call(movePoolscan, data);

    yield put({
      type: actions.MOVE_POOLSCAN_SUCCESS,
      payload: {
        data: response.data,
        movedScanId: data.poolscan_id,
      },
    });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: 'Poolscan moved' });

    return yield call(resetForm);
  } catch (error) {
    yield put({ type: actions.MOVE_POOLSCAN_FAILURE });
    return notification.error({ message: error.message, duration: 10 });
  }
}

export function* callFetchServiceScanSessions({ payload }) {
  try {
    const { data } = yield call(fetchSessions, payload);

    yield put({
      type: actions.FETCH_SERVICE_SCAN_SESSIONS_SUCCESS,
      payload: {
        data: data?.results ?? [],
        total: data.count,
        firstPage: !data.previous,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SERVICE_SCAN_SESSIONS_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_SCAN_SESSIONS_REQUEST, callFetchScanSessions),
    takeEvery(
      actions.FETCH_SCAN_SESSION_BY_ID_REQUEST,
      callFetchScanSessionById,
    ),
    takeEvery(actions.UPDATE_SESSION_REQUEST, callUpdateSession),
    takeEvery(actions.VOID_SCAN_BY_ID_REQUEST, callVoidScan),
    takeEvery(actions.UPDATE_SCAN_BY_ID_REQUEST, callUpdateScan),
    takeEvery(actions.FETCH_SCAN_BY_ID_REQUEST, callFetchScanById),
    takeEvery(actions.UPDATE_TUBE_REQUEST, callUpdateTube),
    takeEvery(actions.DELETE_TUBE_REQUEST, callDeleteTube),
    takeEvery(actions.INVALIDATE_TUBE_REQUEST, callInvalidateTube),
    takeEvery(actions.CREATE_SESSION_REQUEST, callCreateSession),
    takeEvery(actions.FETCH_SESSION_ID_REQUEST, callFetchSessionId),
    takeEvery(actions.CANCEL_SCAN_BY_ID_REQUEST, callCancelScan),
    takeEvery(actions.FETCH_ACTIVE_SCANS_REQUEST, callFetchActiveScans),
    takeEvery(
      actions.CHECK_SCANNER_STATUS_BY_ID_REQUEST,
      callScannerStatusById,
    ),
    takeEvery(actions.DELETE_SCAN_BY_ID_REQUEST, callDeleteScan),
    takeEvery(actions.UPLOAD_SCAN_REQUEST, callUploadScan),
    takeEvery(actions.REOPEN_SESSION_REQUEST, callReopenSession),
    takeEvery(actions.REASSIGN_INTAKE_ITEM_REQUEST, callReassignIntakeItem),
    takeEvery(actions.MOVE_POOLSCAN_REQUEST, callMovePoolscan),
    takeEvery(actions.CREATE_EMPTY_SCAN_REQUEST, callCreateEmptyScan),
    takeEvery(actions.COPY_SCAN_REQUEST, callCopyScan),
    takeEvery(
      actions.FETCH_SERVICE_SCAN_SESSIONS_REQUEST,
      callFetchServiceScanSessions,
    ),
    spawn(scanWatcher),
  ]);
}
