/* eslint-disable import/prefer-default-export */
import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';
// import nameWithExtension from 'utils/nameWithExtension';
// import saveBlobAs from 'utils/saveAsBlob';

// TODO: leave it here, sync template creation
// export const createTemplate = async (payload) => {
//   try {
//     const response = await axiosClient.post('/runs/', {
//       ...payload,
//       responseType: 'blob',
//     });
//     const contentType = response.headers['content-type'];

//     const blobData = new Blob([response.data], { type: contentType });
//     const parsedName = response.headers['content-disposition']
//       ?.split(';')[1]
//       ?.split('="')[1]
//       ?.split('.')[0];

//     saveBlobAs(
//       blobData,
//       nameWithExtension(parsedName || 'Unknown', contentType),
//     );
//     return response;
//   } catch (error) {
//     throw new Error(handleError(error));
//   }
// };

export const createTemplate = async (payload) => {
  try {
    const response = await axiosClient.post('/runs/', {
      ...payload,
    });
    return response;
  } catch (error) {
    throw new Error(handleError(error));
  }
};
