import generateRequestActions from 'redux/factories/generateRequestActions';
import { ActionType } from './types';

const actions: ActionType = {
  FETCH_POOLS_BY_RUN_ID_REQUEST: 'pools/FETCH_POOLS_BY_RUN_ID_REQUEST',
  FETCH_POOLS_BY_RUN_ID_SUCCESS: 'pools/FETCH_POOLS_BY_RUN_ID_SUCCESS',
  FETCH_POOLS_BY_RUN_ID_FAILURE: 'pools/FETCH_POOLS_BY_RUN_ID_FAILURE',

  FETCH_POOLS_BY_COMPANY_ID_REQUEST: 'pools/FETCH_POOLS_BY_COMPANY_ID_REQUEST',
  FETCH_POOLS_BY_COMPANY_ID_SUCCESS: 'pools/FETCH_POOLS_BY_COMPANY_ID_SUCCESS',
  FETCH_POOLS_BY_COMPANY_ID_FAILURE: 'pools/FETCH_POOLS_BY_COMPANY_ID_FAILURE',

  FETCH_ALL_POOLS_REQUEST: 'pools/FETCH_ALL_POOLS_REQUEST',
  FETCH_ALL_POOLS_SUCCESS: 'pools/FETCH_ALL_POOLS_SUCCESS',
  FETCH_ALL_POOLS_FAILURE: 'pools/FETCH_ALL_POOLS_FAILURE',

  PUBLISH_POOL_REQUEST: 'pools/PUBLISH_POOL_REQUEST',
  PUBLISH_POOL_SUCCESS: 'pools/PUBLISH_POOL_SUCCESS',
  PUBLISH_POOL_FAILURE: 'pools/PUBLISH_POOL_FAILURE',

  PUBLISH_POOL_BY_DAY_REQUEST: 'pools/PUBLISH_POOL_BY_DAY_REQUEST',
  PUBLISH_POOL_BY_DAY_SUCCESS: 'pools/PUBLISH_POOL_BY_DAY_SUCCESS',
  PUBLISH_POOL_BY_DAY_FAILURE: 'pools/PUBLISH_POOL_BY_DAY_FAILURE',

  SYNC_POOLS_REQUEST: 'pools/SYNC_POOLS_REQUEST',
  SYNC_POOLS_SUCCESS: 'pools/SYNC_POOLS_SUCCESS',
  SYNC_POOLS_FAILURE: 'pools/SYNC_POOLS_FAILURE',

  ...generateRequestActions(['update'], 'pools', 'pool_result'),
  ...generateRequestActions(['update'], 'pools', 'pool_result_by_day'),
  ...generateRequestActions(['update'], 'pools', 'indiv_samples'),
};

export default actions;
