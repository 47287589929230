import { notification } from 'antd';
import React from 'react';
import { END, eventChannel } from 'redux-saga';
import { call, take } from 'redux-saga/effects';
import { constants } from 'utils/constants';
import cookieStorage from 'utils/cookie';
import sseClient from 'utils/sseClient';
import closeBtn from 'utils/sseHelpers';

async function createChannel(eventSource) {
  return eventChannel((emitter) => {
    eventSource.onopen = (e) => {
      console.info('connection is established');
      emitter(e);
    };

    eventSource.onerror = (err) => {
      console.error(err);
    };

    eventSource.addEventListener('message', (e) => {
      console.info(`message ${e.data}`);
      return emitter({ action: 'message', data: e.data });
    });
    eventSource.addEventListener('disconnect', (e) => {
      // this causes the channel to close
      emitter(END);
    });

    return () => {};

    // return () => {
    //   console.info('closing connection...');
    //   eventSource.close();
    //   emitter(END);
    // };
  });
}

// eslint-disable-next-line import/prefer-default-export
export function* notificationWatcher() {
  const cookie = cookieStorage();
  const isUserAuthorized = cookie.getItem('accessToken');
  if (isUserAuthorized) {
    const eventSource = sseClient(
      `${process.env.REACT_APP_SSE_URL}/notifications/`,
    );

    const channel = yield call(createChannel, eventSource);

    try {
      while (true) {
        const { action, data } = yield take(channel);

        // eslint-disable-next-line default-case
        switch (action) {
          case 'message': {
            const parsedData = JSON.parse(data);
            const key = `open${Date.now()}`;

            const { notificationTypes } = constants;
            const priority = parsedData?.priority;
            const type = notificationTypes.includes(priority)
              ? priority
              : 'error';

            const getContent = () => {
              const runID = parsedData?.run_id;

              if (runID) {
                return {
                  message: 'Run Error',
                  description: (
                    <>
                      <p>{parsedData?.message}</p>
                      <a href={`/analysis-runs/${runID}`}>GO TO THE RUN</a>
                    </>
                  ),
                  duration: null,
                  key,
                  btn: closeBtn({ key }),
                };
              }
              return {
                description: <p>{parsedData?.message}</p>,
                duration: null,
                key,
                btn: closeBtn({ key }),
              };
            };

            const content = getContent();

            notification[type](content);

            break;
          }

          // TODO: What is wrong with default section ???
          // default:
          //   return;
        }
      }
    } catch (error) {
      throw error;
    }
  }
  return;
}
