import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createNote, fetchNotes } from 'services/notes';
import actions from './actions';

export function* callLoadNotes({ payload }) {
  try {
    const { data } = yield call(fetchNotes, payload.data);

    return yield put({
      type: actions.FETCH_NOTES_SUCCESS,
      payload: {
        data,
        firstPage: !data.previous,
        type: payload.type ?? null,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_NOTES_FAILURE });
    return notification.error({ message: error.message, duration: 10 });
  }
}

export function* callCreateNote({ payload }) {
  const { resetForm } = payload;

  try {
    const { data } = yield call(createNote, payload.data);

    yield put({
      type: actions.CREATE_NOTE_SUCCESS,
      payload: { data },
    });

    return yield call(resetForm);
  } catch (error) {
    yield put({ type: actions.CREATE_NOTE_FAILURE });
    return notification.error({ message: error.message, duration: 10 });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_NOTES_REQUEST, callLoadNotes),
    takeEvery(actions.CREATE_NOTE_REQUEST, callCreateNote),
  ]);
}
