import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

export const fetchCompanies = async (params) => {
  try {
    return await axiosClient.get('/companies/', {
      params,
    });
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const getSingleCompany = async (id) => {
  try {
    const company = await axiosClient.get(`/companies/${id}/`);

    return company;
  } catch (error) {
    return error;
  }
};
