import { notification } from 'antd';
import partition from 'lodash.partition';
import uniq from 'lodash.uniq';
import React from 'react';
import { END, eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { callFetchRunMethods } from 'redux/classificators/sagas';
import modalActions from 'redux/modal/actions';
import notesActions from 'redux/notes/actions';
import {
  fetchRun,
  fetchRuns,
  fetchWellplate,
  lockRun,
  removeRun,
  updateRun,
  updateSample,
  updateSamplesInBulk,
  uploadRunResults,
  uploadRunPlot,
} from 'services/analysisRuns';
import { isControlSample } from 'utils/analysisRules';
import { constants } from 'utils/constants';
import cookieStorage from 'utils/cookie';
import { getSampleId } from 'utils/infcovidRules';
import sseClient from 'utils/sseClient';
import closeBtn from 'utils/sseHelpers';
import actions from './actions';
import { getControlSamples, getRunMethods } from './selectors';

export function* callLoadRuns({ payload }) {
  try {
    const response = yield call(fetchRuns, payload);

    yield put({
      type: actions.FETCH_RUNS_SUCCESS,
      payload: {
        data: response.data,
        firstPage: !response.data.previous,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RUNS_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callUploadRunResults({ payload }) {
  try {
    const { data } = yield call(uploadRunResults, payload);

    yield put({
      type: actions.UPLOAD_RUN_RESULTS_SUCCESS,
      payload: data,
    });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: 'Uploaded successfully' });
  } catch (error) {
    notification.error({ message: error.message, duration: null });
  }
}

export function* callUploadRunPlot({ payload }) {
  try {
    const { data } = yield call(uploadRunPlot, payload);

    yield put({
      type: actions.UPLOAD_RUN_PLOT_SUCCESS,
      payload: data,
    });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: 'Uploaded plot file successfully' });
  } catch (error) {
    notification.error({ message: error.message, duration: null });
  }
}

export function* callLoadRun({ payload }) {
  try {
    yield call(callFetchRunMethods, {});

    const runMethods = yield select(getRunMethods);
    const controlSamples = yield select(getControlSamples);
    // TODO: remove
    const filteredControlSamples = controlSamples.filter((e) => e !== '');

    const { data } = yield call(fetchRun, payload.id);

    const [reserved, basic] = partition(data.items, (item) =>
      isControlSample(item?.display_sample_id, filteredControlSamples),
    );

    yield put({
      type: actions.FETCH_RUN_SUCCESS,
      payload: {
        data: {
          ...data,
          id: payload.id,
          items: basic,
          reserved,
        },
        runMethods,
      },
    });

    // TODO: use constants
    const barcodes = basic.map((item) => {
      if (data.run_method === 'INFCOVIDRSV' || data.run_method === 'INFCOVID') {
        return getSampleId(item.display_sample_id);
      }
      return item.display_sample_id;
    });

    return yield put({
      type: notesActions.FETCH_NOTES_REQUEST,
      payload: {
        data: uniq(barcodes),
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RUN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callFetchWellplate({ payload }) {
  try {
    const { data } = yield call(fetchWellplate, payload.id);

    const formatResponse = (response) => {
      return Object.assign(
        {},
        ...response?.map?.((obj) => ({
          letter: obj?.position?.[0],
          [`col${obj?.position?.substr(1)}`]: {
            ...obj,
            status: obj?.status,
          },
        })),
      );
    };

    const wellplates = data?.items;

    const preparedResponse = wellplates.map((wellplate) => {
      const step = wellplate.length === 48 ? 6 : 12;

      return [
        formatResponse(wellplate?.slice?.(0, step)),
        formatResponse(wellplate?.slice?.(step, step * 2)),
        formatResponse(wellplate?.slice?.(step * 2, step * 3)),
        formatResponse(wellplate?.slice?.(step * 3, step * 4)),
        formatResponse(wellplate?.slice?.(step * 4, step * 5)),
        formatResponse(wellplate?.slice?.(step * 5, step * 6)),
        formatResponse(wellplate?.slice?.(step * 6, step * 7)),
        formatResponse(wellplate?.slice?.(step * 7, step * 8)),
      ];
    });

    yield put({
      type: actions.FETCH_WELLPLATE_SUCCESS,
      payload: {
        data: preparedResponse,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_WELLPLATE_FAILURE });

    notification.error({
      message: error.message,
    });
  }
}

export function* callUpdateSample({ payload }) {
  const { id, field } = payload;
  try {
    yield put({ type: modalActions.HIDE_MODAL });

    const response = yield call(updateSample, payload);

    yield put({
      type: actions.UPDATE_SAMPLE_SUCCESS,
      payload: {
        field,
        data: response.data,
      },
    });

    notification.success({
      message: 'Sample updated',
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_SAMPLE_FAILURE,
      payload: {
        id,
        field,
      },
    });

    notification.error({
      message: error.message ?? 'Sample not updated',
    });
  }
}

export function* callUpdateRun({ payload }) {
  try {
    const response = yield call(updateRun, payload);

    yield put({
      type: actions.UPDATE_RUN_SUCCESS,
      payload: {
        data: response.data,
      },
    });

    notification.success({
      message: 'Run updated',
    });

    if (payload.value == constants.runStatuses.published) {
      payload.callback();
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_RUN_FAILURE,
    });

    notification.error({
      message: error.message ?? 'Run not updated',
    });
  }
}

export function* callRemoveRun({ payload }) {
  try {
    yield call(removeRun, payload);

    yield put({
      type: actions.DELETE_RUN_SUCCESS,
      payload: { id: payload.id },
    });

    notification.success({ message: 'Run removed' });
  } catch (error) {
    yield put({
      type: actions.DELETE_RUN_FAILURE,
      payload: { id: payload.id },
    });

    notification.error({
      message: error.message,
    });
  }
}

export function* callUpdateSamplesInBulk({ payload }) {
  const { data, resetSelectedRows } = payload;
  try {
    const formatData = data.map((sample) => ({
      id: sample.sample_id,
      analysis_result: sample.analysis_result,
      rerun_action: sample.rerun_action,
    }));

    const response = yield call(updateSamplesInBulk, formatData);

    yield put({
      type: actions.UPDATE_SAMPLES_IN_BULK_SUCCESS,
      payload: { data },
    });

    yield call(resetSelectedRows);

    notification.success({ message: 'Samples updated' });
  } catch (error) {
    yield put({ type: actions.UPDATE_SAMPLES_IN_BULK_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callLockRun({ payload }) {
  try {
    const { data } = yield call(lockRun, payload);

    yield put({
      type: actions.LOCK_RUN_SUCCESS,
      payload: data,
    });

    yield put({ type: modalActions.HIDE_MODAL });

    // TODO: add run name
    notification.success({ message: 'Run has locked' });
  } catch (error) {
    yield put({ type: actions.LOCK_RUN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

async function createChannel(eventSource) {
  return eventChannel((emitter) => {
    eventSource.onopen = (e) => {
      console.info('connection is established');
      emitter(e);
    };

    eventSource.onerror = (err) => {
      console.error(err);
    };

    eventSource.addEventListener('message', (e) => {
      console.info(`message ${e.data}`);
      return emitter({ action: 'message', data: e.data });
    });
    eventSource.addEventListener('disconnect', (e) => {
      // this causes the channel to close
      emitter(END);
    });

    return () => {};

    // return () => {
    //   console.info('closing connection...');
    //   eventSource.close();
    //   emitter(END);
    // };
  });
}

export function* runUpdatesWatcher() {
  const cookie = cookieStorage();
  const isUserAuthorized = cookie.getItem('accessToken');
  if (isUserAuthorized) {
    const eventSource = sseClient(`${process.env.REACT_APP_SSE_URL_B}/runs/`);

    const channel = yield call(createChannel, eventSource);

    try {
      while (true) {
        const { action, data } = yield take(channel);

        // eslint-disable-next-line default-case
        switch (action) {
          case 'message': {
            const parsedData = JSON.parse(data);
            const key = `open${Date.now()}`;

            yield put({
              type: actions.FETCH_RUN_UPDATES_BY_SSE_SUCCESS,
              payload: {
                data: parsedData,
              },
            });

            if (parsedData?.status?.after === constants.runStatuses.error) {
              notification.error({
                message: 'Run Error',
                description: (
                  <>
                    <a href={`/analysis-runs/${parsedData?.runId}`}>This run</a>{' '}
                    has gotten an error status
                  </>
                ),
                duration: null,
                key,
                btn: closeBtn({ key }),
              });
            }

            break;
          }

          // TODO: What is wrong with default section ???
          // default:
          //   return;
        }
      }
    } catch (error) {
      throw error;
    }
  }
  return;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_RUNS_REQUEST, callLoadRuns),
    takeEvery(actions.UPLOAD_RUN_RESULTS_REQUEST, callUploadRunResults),
    takeEvery(actions.UPLOAD_RUN_PLOT_REQUEST, callUploadRunPlot),
    takeEvery(actions.FETCH_RUN_REQUEST, callLoadRun),
    takeEvery(actions.UPDATE_SAMPLE_REQUEST, callUpdateSample),
    takeEvery(actions.UPDATE_RUN_REQUEST, callUpdateRun),
    takeEvery(actions.FETCH_WELLPLATE_REQUEST, callFetchWellplate),
    takeEvery(actions.DELETE_RUN_REQUEST, callRemoveRun),
    takeEvery(actions.UPDATE_SAMPLES_IN_BULK_REQUEST, callUpdateSamplesInBulk),
    takeEvery(actions.LOCK_RUN_REQUEST, callLockRun),
    spawn(runUpdatesWatcher),
  ]);
}
