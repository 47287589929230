import actions from './actions';

const initialPlotsState = {
  data: null,
  isLoading: false,
  error: null,
};

type ActionType = {
  type: string;
  payload: PayloadType;
};

type PayloadType = {
  data: PlotDataType;
};

type PlotDataType = {
  Amplification?: LineDataType;
  Multicomponent?: LineDataType;
};

type LineDataType = {
  cycle_num: string;
  value: number;
};

const plotsReducer = (state = initialPlotsState, action: ActionType) => {
  switch (action.type) {
    case actions.FETCH_PLOTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_PLOTS_SUCCESS: {
      return {
        ...state,
        data: Object.keys(action.payload).length === 0 ? null : action.payload,
        isLoading: false,
      };
    }
    case actions.FETCH_PLOTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default plotsReducer;
